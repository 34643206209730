<template>
<div class="newCard">
  <div class="msgDiv" >
    <p class="title">{{title}}</p>
    <p class="msgFooter"><span class="yuedu">阅读：{{ yuedu }}</span>
      <span class="sendTime">{{sendTime}}</span></p>
  </div>
  <div class="coverDiv">
    <img class="cover" :src="cover" :alt="title"/>
  </div>
</div>

</template>

<script>
export default {
name: "MyNewCard",

  props:{
  // 阅读量
    yuedu:{
      type:Number,
      default:()=>{return 0}
    },
    // 封面图
    cover:{
      type:String,
      default:()=>{return null}
    },
    sendTime:{
      type:String,
      default:()=>{return '未定义发布时间'}
    },
    // 标题
    title:{
      type:String,
      default:()=>{return '未定义标题'}
    }
  }
}
</script>

<style lang="stylus" scoped>
.msgDiv{
  display flex
  justify-content space-around
  flex-direction column
  width 100%
  margin 10px 10px
  margin-right 20px
}
.msgFooter{
  margin-top 20px
  font-size 25px
  color #c5cad5

}

.coverDiv{
  margin: 10px 10px
}
.newCard
  display flex
  width 100%
  margin 30px 0px
 .cover
    width 150px
    height 150px
    object-fit cover
    border-radius 10px
 .title
    font-size 30px
 .yuedu
    float left
 .sendTime
     float right

</style>
