<template>
  <div >
    <h5 id="newsListTitle">{{ categoryName }}</h5>
<!--    <div class="newsList" v-for="(item,index) in newVideoList"  :key="'video'+index" @click="$router.push('/newvideopage/'+item.id)" >
      <MyNewCard   :yuedu="item.post_hits" :send-time="item.published_time | pushedTime" :title="item.post_title"  :pushed-day="item.published_time | pushedDay" :yearMonth="item.published_time | pushedYearMonth" :cover="item.thumbnail === '' ? require('@/assets/menuicon/0.png'):item.thumbnail "></MyNewCard>
      <hr>
    </div>-->
    <div class="newsList" v-for="(item,index) in newList"  :key="index" @click="goNewsPage(item.id)" >
      <MyNewCard   :yuedu="item.post_hits" :send-time="item.published_time | pushedTime" :title="item.post_title"  :pushed-day="item.published_time | pushedDay" :yearMonth="item.published_time | pushedYearMonth" :cover="item.thumbnail === '' ? require('@/assets/menuicon/0.png'):item.thumbnail "></MyNewCard>
      <hr>
    </div>
  </div>
</template>

<script>
import MyNewCard from "@/components/News/MyNewCard";
import moment from 'moment'
import {getToken} from "@/util/auth";
import vData from "@/views/vdata";
import api from "@/api";

export default {
name: "News",
  components:{
    MyNewCard
  },
  data(){
  return {
    // 新闻列表
    newList:[],
    categoryName:'未定义标题',
    categoryId:0,
    // 视频列表
    newVideoList:[]
    }
  },
  mounted() {
    let debug = this.categoryId = this.$route.params.debug
    this.categoryId = this.$route.params.id
    this.categoryName = this.$route.params.categoryName
    // 检查有没token
    let t = getToken()
    if (t === "" && debug === undefined) {
       api.user.getUserInfo()
      // 跳转到首页页面
     // this.$router.push('/')
      //return
    }else {
      if (this.categoryId == 4)  {
        this.newVideoList = vData
      }

    }



    this.getNewsList()
  },
  filters:{
    pushedTime(data){
      return moment(data).format('YYYY-MM-DD')
    },
    pushedYearMonth(data){
      return moment(data).format('YYYY-MM')
    },
    pushedDay(data){
      return moment(data).format('DD')
    }
  },
  methods:{
    goNewsPage(id){
      this.$router.push('/newpage/'+id)
    },
    getNewsList(){
      this.$API.news.getCategoryPostLists(this.categoryId).then(res=>{
        this.newList = res.data.list
      })
    }
  }
}
</script>

<style scoped>
.newsList{
  margin: 20px;
  //letter-spacing: 1px;
  line-height:45px;
}
hr{
  margin: 0;
}
#newsListTitle{
  text-align: center;
  font-size: 50px;
  padding: 40px 0px;
  background-color: #f2f5f5;
}
hr{
  background-color:#c5cad5;
  height:1px;
  border:none;
}
</style>
